import { useMemo } from 'react';

export const useWebsiteMenu = (website, menuItems) => {
    const enabled = !!website.menu?.enabled;
    const homePageId = website.homePageId;
    const items = menuItems || website.menu?.items;

    return useMemo(
        () => {
            if (!items) {
                return {
                    enabled,
                    items: [],
                };
            }

            const ids = website.pages.map((page) => page._id);
            const result = items.filter(({ page }) => page === homePageId);

            for (const item of items) {
                if ((item.page !== homePageId) && ids.includes(item.page)) {
                    result.push(item);
                }
            }

            return {
                enabled,
                items: result,
            };
        },
        [items, homePageId, enabled]
    );
};
